'use client'

import type { MasterDataProviderProps } from 'master-data/types'
import {
	type Dispatch,
	type SetStateAction,
	createContext,
	useCallback,
	useMemo,
	useState,
} from 'react'
import type { MasterData } from 'types/masterData'
import type { CustomDomainData } from 'types/masterData/domainData/domainData'

export type UpdateMasterDataParams = Partial<
	Omit<MasterData, 'custom'> & { custom: Partial<MasterData['custom']> }
>

interface LayoutSetStateMasterDataContextType {
	updateMasterData: (newMasterdata: UpdateMasterDataParams) => void
	setMasterData: Dispatch<SetStateAction<MasterData>>
}

export interface LayoutMasterDataIsReadyContextValue {
	masterDataIsReady: boolean
	setMasterDataIsReady: Dispatch<SetStateAction<boolean>>
}

export const LayoutStateMasterDataContext = createContext<
	MasterData | undefined
>(undefined)

export const LayoutSetStateMasterDataContext = createContext<
	LayoutSetStateMasterDataContextType | undefined
>(undefined)

export const LayoutMasterDataIsReadyContext = createContext<
	LayoutMasterDataIsReadyContextValue | undefined
>(undefined)

export function LayoutMasterDataProvider({
	masterData: initialMasterData,
	children,
}: Readonly<MasterDataProviderProps>) {
	const [masterData, setMasterData] = useState(initialMasterData)
	const [masterDataIsReady, setMasterDataIsReady] = useState(false)

	const updateMasterData = useCallback(
		(newMasterData: UpdateMasterDataParams): void => {
			setMasterData((currentMasterData) => {
				const { custom: currentCustom, ...currentData } = currentMasterData
				const { custom: newCustom, ...newData } = newMasterData

				const result: MasterData = { ...currentData, ...newData }

				const mergedCustomData = {
					...(currentCustom ?? {}),
					...(newCustom ?? {}),
				} as CustomDomainData

				if (Object.keys(mergedCustomData).length > 0) {
					result.custom = mergedCustomData
				}

				return result
			})
		},
		[]
	)

	const setValue = useMemo(
		() => ({ updateMasterData, setMasterData }),
		[updateMasterData]
	)

	const masterDataIsReadyValue = useMemo(
		() => ({ masterDataIsReady, setMasterDataIsReady }),
		[masterDataIsReady]
	)

	return (
		<LayoutStateMasterDataContext.Provider value={masterData}>
			<LayoutSetStateMasterDataContext.Provider value={setValue}>
				<LayoutMasterDataIsReadyContext.Provider value={masterDataIsReadyValue}>
					{children}
				</LayoutMasterDataIsReadyContext.Provider>
			</LayoutSetStateMasterDataContext.Provider>
		</LayoutStateMasterDataContext.Provider>
	)
}
