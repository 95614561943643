import checkoutEnvVars from 'checkout/config/env/shop/dev.json' with {
	type: 'json',
}
import shopEnvVars from 'site/config/env/shop/dev.json' with { type: 'json' }
import type { Channel, Environment } from 'types/masterData'

type TypedEnvVars = {
	NEXT_PUBLIC_CHANNEL: Channel
	NEXT_PUBLIC_ENV: Environment
}

type EnvVars = (typeof shopEnvVars & typeof checkoutEnvVars) & TypedEnvVars

function isClient() {
	return Object.keys(process.env).length === 0
}

export const env = new Proxy(
	{
		NEXT_PUBLIC_CHANNEL: process.env.NEXT_PUBLIC_CHANNEL as Channel,
		NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV as Environment,
		NEXT_PUBLIC_GTM_SERVER_SIDE: process.env
			.NEXT_PUBLIC_GTM_SERVER_SIDE as string,
		NEXT_PUBLIC_SITE_BASE_URL: process.env.NEXT_PUBLIC_SITE_BASE_URL as string,
		NEXT_PUBLIC_API_DOMAIN: process.env.NEXT_PUBLIC_API_DOMAIN as string,
		NEXT_PUBLIC_CURRENT_ORIGIN: process.env
			.NEXT_PUBLIC_CURRENT_ORIGIN as string,
		NEXT_PUBLIC_ANALYTICS_MEASUREMENT_ID: process.env
			.NEXT_PUBLIC_ANALYTICS_MEASUREMENT_ID as string,
		FF_OPTIMIZELY_SDK: process.env.FF_OPTIMIZELY_SDK as string,
		NEXT_PUBLIC_AB_TEST_OPTIMIZELY_SDK: process.env
			.NEXT_PUBLIC_AB_TEST_OPTIMIZELY_SDK as string,
		NEXT_PUBLIC_LEGAL_APIKEY: process.env.NEXT_PUBLIC_LEGAL_APIKEY as string,
		NEXT_PUBLIC_STATIC_CONFIG_ACCESS_TOKEN: process.env
			.NEXT_PUBLIC_STATIC_CONFIG_ACCESS_TOKEN as string,
		NEXT_PUBLIC_STATIC_CONFIG_APIKEY: process.env
			.NEXT_PUBLIC_STATIC_CONFIG_APIKEY as string,
		NEXT_PUBLIC_LEGAL_DELIVERYTOKEN: process.env
			.NEXT_PUBLIC_LEGAL_DELIVERYTOKEN as string,
		NEXT_PUBLIC_CARD_PAYMENT_IFRAME_URL: process.env
			.NEXT_PUBLIC_CARD_PAYMENT_IFRAME_URL as string,
		NEXT_PUBLIC_FASHION_ASSISTANT_HEADER: process.env
			.NEXT_PUBLIC_FASHION_ASSISTANT_HEADER as string,
		NEXT_PUBLIC_HELP_CS_ACCESS_TOKEN: process.env
			.NEXT_PUBLIC_HELP_CS_ACCESS_TOKEN as string,
		NEXT_PUBLIC_HELP_CS_APIKEY: process.env
			.NEXT_PUBLIC_HELP_CS_APIKEY as string,
		NEXT_PUBLIC_GIFT_VOUCHER_BASE_URL: process.env
			.NEXT_PUBLIC_GIFT_VOUCHER_BASE_URL as string,
		NEXT_PUBLIC_PRODUCT_CUSTOMIZATION_BASE_URL: process.env
			.NEXT_PUBLIC_PRODUCT_CUSTOMIZATION_BASE_URL as string,
		NEXT_PUBLIC_REGISTRY_PERSONALIZATION_HEADER: process.env
			.NEXT_PUBLIC_REGISTRY_PERSONALIZATION_HEADER as string,
		NEXT_PUBLIC_IS_APP: process.env.NEXT_PUBLIC_IS_APP as string,
		NEXT_PUBLIC_IRIS_CHAT_URL: process.env.NEXT_PUBLIC_IRIS_CHAT_URL as string,
		NEXT_PUBLIC_ADYEN_CLIENT_KEY: process.env
			.NEXT_PUBLIC_ADYEN_CLIENT_KEY as string,
		NEXT_PUBLIC_PAYPAL_CLIENT_ID: process.env
			.NEXT_PUBLIC_PAYPAL_CLIENT_ID as string,
		VERCEL_ACTIVATED: process.env.VERCEL_ACTIVATED as string,
		NEXT_PUBLIC_CIAM_CLIENT_ID: process.env
			.NEXT_PUBLIC_CIAM_CLIENT_ID as string,
		NEXT_PUBLIC_ORCHESTRATOR_DOMAIN: process.env
			.NEXT_PUBLIC_ORCHESTRATOR_DOMAIN as string,
		NEXT_PUBLIC_ADOBE_DAM_DOMAIN: process.env
			.NEXT_PUBLIC_ADOBE_DAM_DOMAIN as string,
		NEXT_PUBLIC_IRIS_DOMAIN: process.env.NEXT_PUBLIC_IRIS_DOMAIN as string,
	} as EnvVars,
	{
		get(target, name) {
			if (typeof name !== 'string') {
				throw new Error('Accessing env with symbol is not allowed')
			}
			if (process.env[name]) {
				return process.env[name]
			}
			if (isClient() && !name.startsWith('NEXT_PUBLIC_')) {
				throw new Error(
					`Environment variable ${name} is server only and can't be used in the client`
				)
			}
			if (!target[name as keyof EnvVars]) {
				throw new Error(`Environment variable ${name} is not defined`)
			}
			return target[name as keyof EnvVars]
		},
	}
)
